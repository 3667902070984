<template>
  <div class="home">
    <img alt="Vue logo" src="../assets/404.png">
  </div>
</template>

<script>

export default {
  
}
</script>
<style>
.home{
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home img{
  width: 550px;
  /* margin: 50% auto 0; */
}
</style>
